import { SupportedLlm, Llm, Provider, ExternalLlm } from '@kindo/universal';
import DisplayedSettingsModel, { CostTier, SecurityTier } from './ModelChoice.types';
import { Color } from '~/components/core';
export const COST_TIER_INDICATOR_TEXT: Record<CostTier, string> = {
  [CostTier.LOW]: '$',
  [CostTier.MEDIUM]: '$$',
  [CostTier.HIGH]: '$$$'
};
export const SECURITY_TIER_INDICATOR_SYMBOL: string = '⦿';
export const SECURITY_TIER_INDICATOR_COLOR: Record<SecurityTier, Color> = {
  [SecurityTier.WEAK]: Color.RED,
  [SecurityTier.MODERATE]: Color.ORANGE,
  [SecurityTier.STRONG]: Color.GREEN
};
export const PROVIDER_SECURITY_LEVELS: Record<Provider, SecurityTier> = {
  [Provider.AZURE]: SecurityTier.MODERATE,
  [Provider.ANTHROPIC]: SecurityTier.MODERATE,
  [Provider.COHERE]: SecurityTier.MODERATE,
  [Provider.GOOGLE]: SecurityTier.MODERATE,
  [Provider.GROQ]: SecurityTier.MODERATE,
  [Provider.HUGGING_FACE]: SecurityTier.MODERATE,
  [Provider.IBM]: SecurityTier.MODERATE,
  [Provider.KINDO]: SecurityTier.STRONG,
  [Provider.TOGETHER_AI]: SecurityTier.MODERATE
};

/*
costTier
  ranked according to model's
    (input_cost_per_token + output_cost_per_token) / 2
  (values found in litellm/model_prices_and_context_window.json)
  LOW:    deepSeek = 2.1e-07, mixtral = 2.7e-07, llama = 7.2e-07, haiku = 7.5e-07
  MEDIUM: geminiFlash = 1e-06, gpt35Turbo = 1.75e-06, sonnet = 9e-06
  HIGH:   geminiPro = 1e-05, gpt-4o = 1e-05, gpt4Turbo = 2e-05, opus = 4.4999999999999996e-05, 
          gpt4 = 4.5e-05
*/
export const MODEL_COST_TIERS: Record<SupportedLlm, CostTier> = {
  // "input_cost_per_token": 0.00000025, "output_cost_per_token": 0.00000125,
  [Llm.CLAUDE_3_HAIKU]: CostTier.LOW,
  // "input_cost_per_token": 0.000015, "output_cost_per_token": 0.000075,
  [Llm.CLAUDE_3_OPUS]: CostTier.HIGH,
  // "input_cost_per_token": 0.000001, "output_cost_per_token": 0.000005,
  [Llm.CLAUDE_3_5_HAIKU]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.000003, "output_cost_per_token": 0.000015,
  [Llm.CLAUDE_3_5_SONNET]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.000003, "output_cost_per_token": 0.000015,
  [Llm.CLAUDE_3_SONNET]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.00000050, "output_cost_per_token": 0.0000015
  [Llm.COMMAND_R]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.00000014, "output_cost_per_token": 0.00000028,
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: CostTier.LOW,
  // "input_cost_per_token": 0.0000005, "output_cost_per_token": 0.0000015,
  [Llm.GEMINI_1_5_FLASH]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.000005,  "output_cost_per_token": 0.000015,
  [Llm.GEMINI_1_5_PRO]: CostTier.HIGH,
  // "input_cost_per_token": 0.00000020, "output_cost_per_token": 0.00000020,
  [Llm.GEMMA_2_9B_IT]: CostTier.LOW,
  // "input_cost_per_token": 0.0000015, "output_cost_per_token": 0.000002,
  [Llm.GPT_3_5_TURBO]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.000005, "output_cost_per_token": 0.000015,
  [Llm.GPT_4O]: CostTier.HIGH,
  // "input_cost_per_token": 0.00000015, "output_cost_per_token": 0.00000060
  [Llm.GPT_4O_MINI]: CostTier.LOW,
  // "input_cost_per_token": 0.00001, "output_cost_per_token": 0.00003
  [Llm.GPT_4_TURBO]: CostTier.HIGH,
  // "input_cost_per_token": 0.0000006, "output_cost_per_token": 0.0000006
  [Llm.GRANITE_13B_CHAT_V2]: CostTier.LOW,
  // "input_cost_per_token": 0.00000059, "output_cost_per_token": 0.00000079
  [Llm.GRANITE_3_8B_INSTRUCT]: CostTier.LOW,
  [Llm.LLAMA_3_1_70B]: CostTier.LOW,
  [Llm.LLAMA_3_1_70B_INTERNAL]: CostTier.LOW,
  // "input_cost_per_token": 0.00000064, "output_cost_per_token": 0.00000080
  [Llm.LLAMA_3_1_8B]: CostTier.LOW,
  [Llm.LLAMA_3_70B]: CostTier.LOW,
  // "input_cost_per_token": 0.00000027, "output_cost_per_token": 0.00000027
  [Llm.MIXTRAL_GROQ]: CostTier.LOW,
  // "input_cost_per_token": 0.000003, "output_cost_per_token": 0.000012
  [Llm.O1_MINI]: CostTier.MEDIUM,
  // "input_cost_per_token": 0.000015, "output_cost_per_token": 0.00006
  [Llm.O1_PREVIEW]: CostTier.HIGH,
  // "input_cost_per_token": 0.0000009, "output_cost_per_token": 0.0000009
  [Llm.QWEN2_72B_INSTRUCT]: CostTier.LOW,
  [Llm.SAUL_INSTRUCT_V1]: CostTier.LOW,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: CostTier.LOW,
  [Llm.WHITERABBITNEO_33B]: CostTier.LOW,
  [Llm.WHITERABBITNEO_R1_32B]: CostTier.LOW
};
const MODELS: Record<ExternalLlm, DisplayedSettingsModel> = {
  [Llm.CLAUDE_3_5_SONNET]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: 'The most intelligent model in the Claude family, with the highest level of intelligence and capability for complex tasks such as context-sensitive customer support and orchestrating multi-step workflows.',
    usageTag: 'Chat + Agents',
    link: 'https://www.anthropic.com/news/claude-3-5-sonnet'
  },
  [Llm.CLAUDE_3_HAIKU]: {
    type: 'Text Generation',
    tags: [],
    description: 'The fastest model in the Claude 3 family.',
    usageTag: 'Chat + Agents',
    link: 'https://www.anthropic.com/news/claude-3-family'
  },
  [Llm.CLAUDE_3_OPUS]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: 'Opus outperforms its peers on most of the common evaluation benchmarks for AI systems, including undergraduate level expert knowledge (MMLU), graduate level expert reasoning (GPQA), basic mathematics (GSM8K), and more. It exhibits near-human levels of comprehension and fluency on complex tasks.',
    usageTag: 'Chat + Agents',
    link: 'https://www.anthropic.com/news/claude-3-family'
  },
  [Llm.CLAUDE_3_SONNET]: {
    type: 'Text Generation',
    tags: [],
    description: 'For the vast majority of workloads, Sonnet is 2x faster than Claude 2 and Claude 2.1 with higher levels of intelligence. It excels at tasks demanding rapid responses, like knowledge retrieval or sales automation.',
    usageTag: 'Chat + Agents',
    link: 'https://www.anthropic.com/news/claude-3-family'
  },
  [Llm.CLAUDE_3_5_HAIKU]: {
    type: 'Text Generation',
    tags: [],
    description: 'Fast model optimized for quick responses while maintaining high quality output',
    usageTag: 'Chat + Agents',
    link: 'https://www.anthropic.com/news/3-5-models-and-computer-use'
  },
  [Llm.COMMAND_R]: {
    type: 'Text Generation',
    description: 'This model is optimized for conversational interaction and long context tasks.',
    usageTag: 'Available by Request',
    link: 'https://docs.cohere.com/docs/command-r'
  },
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: {
    type: 'Code Completion',
    tags: [],
    description: 'DeepSeek-Coder series, a range of open-source code models with sizes from 1.3B to 33B, trained from scratch on 2 trillion tokens',
    usageTag: 'Coding Copilot',
    link: 'https://deepseekcoder.github.io/'
  },
  [Llm.GEMINI_1_5_FLASH]: {
    type: 'Multi-Modal',
    tags: [],
    description: "A lightweight version of Google's multimodal Gemini 1.5 Pro.",
    usageTag: 'Chat + Agents',
    link: 'https://deepmind.google/technologies/gemini/flash/'
  },
  [Llm.GEMINI_1_5_PRO]: {
    type: 'Multi-Modal',
    tags: ['Popular'],
    description: 'Enhanced understanding across modalities, with a context window of 1 million token.',
    usageTag: 'Chat + Agents',
    link: 'https://deepmind.google/technologies/gemini/pro/'
  },
  [Llm.GEMMA_2_9B_IT]: {
    type: 'Text Generation',
    tags: [],
    description: "Google's Gemma 2 9B model, optimized for instruction following and chat.",
    usageTag: 'Chat + Agents',
    link: 'https://blog.google/technology/developers/google-gemma-2/'
  },
  [Llm.GPT_3_5_TURBO]: {
    type: 'Text Generation',
    tags: [],
    description: 'This model is known for its high-speed text generation capabilities.',
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/gpt-3-5'
  },
  [Llm.GPT_4O]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: 'This model is known for its high-speed, advanced text generation capabilities.',
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/gpt-4o'
  },
  [Llm.GPT_4O_MINI]: {
    type: 'Text Generation',
    tags: [],
    description: "OpenAI's most cost-efficient small model with superior textual intelligence and multimodal reasoning. Cheaper than GPT-3.5 Turbo but more powerful.",
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/gpt-4o-mini'
  },
  [Llm.GPT_4_TURBO]: {
    type: 'Text Generation',
    tags: [],
    description: 'This model is known for its high-speed, advanced text generation capabilities.',
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/gpt-4-turbo-and-gpt-4'
  },
  [Llm.GRANITE_13B_CHAT_V2]: {
    type: 'Text Generation',
    tags: [],
    description: 'This model is an open source model from IBM that supports general tasks like QA, summarization, generation, extraction, classification, etc.',
    usageTag: 'Chat + Agents',
    link: 'https://www.ibm.com/docs/en/watsonx/w-and-w/2.0.x?topic=models-granite-13b-chat-v2-model-card'
  },
  [Llm.GRANITE_3_8B_INSTRUCT]: {
    type: 'Text Generation',
    tags: [],
    description: 'This model is the first of the Granite 3.0 series, outperforming or matching similarly sized models from leading model providers on many academic and industry benchmarks, showcasing strong performance, transparency and safety.',
    usageTag: 'Chat + Agents',
    link: 'https://www.ibm.com/granite'
  },
  [Llm.LLAMA_3_1_70B]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: 'Llama 3.1 70B is an open source model developed by Meta AI. It is optimized for multilingual dialogue use cases and outperforms many of the available open source and closed chat models on common industry benchmarks.',
    usageTag: 'Chat + Agents',
    link: 'https://llama.meta.com/docs/model-cards-and-prompt-formats/llama3_1'
  },
  [Llm.LLAMA_3_70B]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: 'Llama 3 70B is an open source model developed by Meta AI. It provides comparable or better performance to GPT-4 and Claude 3.',
    usageTag: 'Chat + Coding Copilot',
    link: 'https://llama.meta.com/llama3'
  },
  [Llm.MIXTRAL_GROQ]: {
    type: 'Text Generation',
    description: 'This model is known for its mixed expertise.',
    usageTag: 'Chat + Agents',
    link: 'https://mistral.ai/news/mixtral-of-experts/'
  },
  [Llm.O1_MINI]: {
    type: 'Text Generation',
    tags: ['New'],
    description: 'A smaller, faster version of the o1 model that still has its improved reasoning capabilities.',
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/o1-mini'
  },
  [Llm.O1_PREVIEW]: {
    type: 'Text Generation',
    tags: ['Popular'],
    description: "OpenAI's preview model for advanced reasoning capabilities.",
    usageTag: 'Chat + Agents',
    link: 'https://platform.openai.com/docs/models/o1-preview'
  },
  [Llm.QWEN2_72B_INSTRUCT]: {
    type: 'Text Generation',
    description: 'This model is known to excel in language understanding, multilingual capabilities, coding, mathematics, and reasoning.',
    usageTag: 'Chat + Agents',
    link: 'https://arxiv.org/html/2407.10671v1'
  },
  [Llm.SAUL_INSTRUCT_V1]: {
    type: 'Text Generation',
    description: 'This model is tailored for legal domain.',
    usageTag: 'Available by Request',
    link: 'https://huggingface.co/Equall/Saul-Instruct-v1'
  },
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: {
    type: 'Text Generation',
    description: 'Known for its advanced cybersecurity capabilities.',
    usageTag: 'Chat + Agents',
    link: 'https://huggingface.co/WhiteRabbitNeo'
  },
  [Llm.WHITERABBITNEO_33B]: {
    type: 'Text Generation + Code Completion',
    description: 'Known for its proficiency in coding and cybersecurity.',
    usageTag: 'Chat + Agents',
    link: 'https://huggingface.co/WhiteRabbitNeo/WhiteRabbitNeo-33B-v1.5'
  },
  [Llm.WHITERABBITNEO_R1_32B]: {
    type: 'Text Generation',
    description: 'This model is the first of the WhiteRabbitNeo Reasoning series of models. WhiteRabbitNeo-R1 is optimized for reasoning tasks.',
    usageTag: 'Chat + Agents',
    link: 'https://huggingface.co/WhiteRabbitNeo/WhiteRabbitNeo-R1-32B'
  }
};
export default MODELS;