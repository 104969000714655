import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
/* eslint-disable react/jsx-props-no-spreading */
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { ButtonType, Color, Size, Typography, TypographySize, TypographyWeight } from '../core';
import { TypographyWrap } from '../core/Typography';
import { CopyButton } from '../shared';
type MarkdownProps = {
  children: string;
  codeBlockTitle?: string;
  styleDocsComponents?: boolean;
  textColor?: Color;
};
enum CalloutType {
  NOTE = 'NOTE',
  TIP = 'TIP',
  WARNING = 'WARNING',
}
const InlineCode = _styled.code.withConfig({
  displayName: "Markdown__InlineCode",
  componentId: "sc-1gy2nk-0"
})(({
  styleDocsComponents
}) => [{
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 246 / var(--tw-bg-opacity))",
  "padding": "0.25rem"
}, styleDocsComponents ? {
  "--tw-bg-opacity": "0.5",
  "--tw-text-opacity": "1",
  "color": "rgb(62 61 65 / var(--tw-text-opacity))"
} : {
  "--tw-text-opacity": "1",
  "color": "rgb(239 68 68 / var(--tw-text-opacity))"
}]);
const MultilineInlineCode = _styled.code.withConfig({
  displayName: "Markdown__MultilineInlineCode",
  componentId: "sc-1gy2nk-1"
})({
  "display": "block",
  "overflowX": "auto",
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 246 / var(--tw-bg-opacity))",
  "padding": "0.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(239 68 68 / var(--tw-text-opacity))"
});
const SyntaxHighlighterHeader = _styled.div.withConfig({
  displayName: "Markdown__SyntaxHighlighterHeader",
  componentId: "sc-1gy2nk-2"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderTopLeftRadius": "0.5rem",
  "borderTopRightRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(62 61 65 / var(--tw-bg-opacity))",
  "paddingLeft": "0.75rem"
});
const TableData = _styled.td.withConfig({
  displayName: "Markdown__TableData",
  componentId: "sc-1gy2nk-3"
})({
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const TableHeader = _styled.th.withConfig({
  displayName: "Markdown__TableHeader",
  componentId: "sc-1gy2nk-4"
})({
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(209 213 219 / var(--tw-border-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const Table = _styled.table.withConfig({
  displayName: "Markdown__Table",
  componentId: "sc-1gy2nk-5"
})({
  "borderTopLeftRadius": "0.5rem",
  "borderTopRightRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(62 61 65 / var(--tw-bg-opacity))"
});
const CalloutContainer = _styled.div.withConfig({
  displayName: "Markdown__CalloutContainer",
  componentId: "sc-1gy2nk-6"
})(({
  type
}) => [{
  "marginTop": "1rem",
  "marginBottom": "1rem",
  "borderTopRightRadius": "0.25rem",
  "borderBottomRightRadius": "0.25rem",
  "borderLeftWidth": "4px",
  "padding": "1rem"
}, type === CalloutType.NOTE && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(59 130 246 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(219 234 254 / var(--tw-bg-opacity))"
}, type === CalloutType.WARNING && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(234 179 8 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 249 195 / var(--tw-bg-opacity))"
}, type === CalloutType.TIP && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(34 197 94 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
}]);
const getMarkdownComponents = ({
  codeBlockTitle,
  textColor,
  styleDocsComponents
}: {
  codeBlockTitle: string | undefined;
  styleDocsComponents: boolean;
  textColor: Color | undefined;
}): Partial<Components> => ({
  p: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.SMALL} />,
  // Treat H4-H6 as medium text (same as H3), the LLM is instructed not to use H4-H6
  h6: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h5: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h4: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  // The LLM has been instructed to return H1-H3 for headings
  h3: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.MEDIUM} weight={TypographyWeight.SEMI_BOLD} />,
  h2: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.LARGE} weight={TypographyWeight.SEMI_BOLD} />,
  h1: ({
    node,
    ...props
  }) => <Typography {...props as any} color={textColor} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} />,
  ul: ({
    node,
    ...props
  }) => <_StyledUl {...props as any} />,
  ol: ({
    node,
    ...props
  }) => <_StyledOl {...props as any} />,
  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-shadow
  li: ({
    node,
    children,
    ...props
  }) => <_StyledLi {...props as any}>
      <Typography color={textColor} size={TypographySize.SMALL}>
        {children}
      </Typography>
    </_StyledLi>,
  // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
  code({
    node,
    className,
    children,
    ref,
    ...props
  }) {
    // If the className is undefined, it is a generic inline code snippet
    if (!className) {
      if (typeof children === 'string' && children.includes('\n')) {
        return <MultilineInlineCode {...props}>{children}</MultilineInlineCode>;
      }
      return <InlineCode styleDocsComponents={styleDocsComponents}>
          {children}
        </InlineCode>;
    }

    // If the className is defined, pass the language to the syntax highlighter
    const match = /language-(\w+)/.exec(className || '');
    return <>
        <SyntaxHighlighterHeader>
          {codeBlockTitle && <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL}>
              {codeBlockTitle}
            </Typography>}
          <CopyButton buttonType={ButtonType.TEXT} size={Size.SMALL} text={String(children)} textColor={Color.GRAY_LIGHT} />
        </SyntaxHighlighterHeader>
        <SyntaxHighlighter {...props} ref={ref as any} language={match ? match[1] : undefined} PreTag="div" style={xonokai}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      </>;
  },
  a: ({
    children,
    href
  }) => <Typography color={Color.NAVY} href={href ?? ''} size={TypographySize.SMALL}>
      {children}
    </Typography>,
  // Markdown table styling
  table: ({
    children
  }) => styleDocsComponents ? <Table>{children}</Table> : <table>{children}</table>,
  th: ({
    children
  }) => styleDocsComponents ? <TableHeader>
        <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL} weight={TypographyWeight.SEMI_BOLD} wrap={TypographyWrap.NO_WRAP}>
          {children}
        </Typography>
      </TableHeader> : <th>{children}</th>,
  td: ({
    children
  }) => styleDocsComponents ? <TableData>
        <Typography color={Color.GRAY_LIGHT} size={TypographySize.SMALL}>
          {children}
        </Typography>
      </TableData> : <td>{children}</td>,
  blockquote: ({
    children
  }) => <CalloutContainer type={CalloutType.TIP}>{children}</CalloutContainer>
});
const Markdown: React.FC<MarkdownProps> = ({
  children,
  textColor,
  codeBlockTitle,
  styleDocsComponents = false
}) => <_StyledReactMarkdown components={getMarkdownComponents({
  codeBlockTitle,
  textColor,
  styleDocsComponents
})} remarkPlugins={[remarkGfm]}>
    {children}
  </_StyledReactMarkdown>;
export default Markdown;
var _StyledUl = _styled("ul").withConfig({
  displayName: "Markdown___StyledUl",
  componentId: "sc-1gy2nk-7"
})({
  "listStylePosition": "outside",
  "listStyleType": "disc",
  "paddingLeft": "0.5rem"
});
var _StyledOl = _styled("ol").withConfig({
  displayName: "Markdown___StyledOl",
  componentId: "sc-1gy2nk-8"
})({
  "listStylePosition": "outside",
  "listStyleType": "decimal",
  "paddingLeft": "0.5rem"
});
var _StyledLi = _styled("li").withConfig({
  displayName: "Markdown___StyledLi",
  componentId: "sc-1gy2nk-9"
})({
  "marginLeft": "1.5rem"
});
var _StyledReactMarkdown = _styled(ReactMarkdown).withConfig({
  displayName: "Markdown___StyledReactMarkdown",
  componentId: "sc-1gy2nk-10"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});