export enum CostTier {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}
export enum SecurityTier {
  MODERATE = 'MODERATE',
  STRONG = 'STRONG',
  WEAK = 'WEAK',
}
type DisplayedSettingsModel = {
  description: string;
  link: string;
  type: string;
  usageTag: string;
  tags?: string[];
};
export default DisplayedSettingsModel;